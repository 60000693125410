/**
 * Translate description: Main content
 */

import React from 'react';

import Box from '@mui/joy/Box';

export default function MainContent({ children }) {
  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: { xs: 2, md: 6 },
        pt: {
          xs: 'calc(12px + var(--Header-height))',
          sm: 'calc(12px + var(--Header-height))',
          md: 3,
        },
        pb: { xs: 2, sm: 2, md: 3 },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        height: '100dvh',
        overflow: 'auto',
        gap: 1,
      }}
    >
      {children}
    </Box>
  )
}