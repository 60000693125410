import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    backend: {
      loadPath: `${process.env.REACT_APP_TMBACK_URL}/api/strings-admin/all/{{lng}}`
    }
  });

export default i18next;