import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import { checkAuthStatus } from './utils/authService';
import UserContext from './context/UserContext';

import AdminPageLayout from './components/layout/AdminPageLayout';

// https://legacy.reactjs.org/docs/code-splitting.html#route-based-code-splitting


const NotFoundPage = lazy(() => import('./pages/404'));
const Login = lazy(() => import('./pages/Login'));
const Home = lazy(() => import('./pages/Home'));
const Dashboard = lazy(() => import('./pages/Dashboard'));

const StringsIndex = lazy(() => import('./pages/strings/Index'));

const UsersIndex = lazy(() => import('./pages/users/Index'));
const UserEdit = lazy(() => import('./pages/users/Edit'));

const TagsIndex = lazy(() => import('./pages/tags/Index'));
const TagEdit = lazy(() => import('./pages/tags/Edit'));

const PostsIndex = lazy(() => import('./pages/posts/Index'));
const PostEdit = lazy(() => import('./pages/posts/Edit'));

const NewsIndex = lazy(() => import('./pages/news/Index'));
const NewsEdit = lazy(() => import('./pages/news/Edit'));

const PostCategoriesIndex = lazy(() => import('./pages/posts-categories/Index'));
const PostCategoryEdit = lazy(() => import('./pages/posts-categories/Edit'));

const NewsCategoriesIndex = lazy(() => import('./pages/news-categories/Index'));
const NewsCategoryEdit = lazy(() => import('./pages/news-categories/Edit'));


// import ArticlesIndex from './pages/articles/Index';
// import { ArticleEdit } from './pages/articles/Edit';

// const ArticlesIndex = lazy(() => import('./pages/articles/Index'));
// const ArticleEdit = lazy(() => import('./pages/articles/Edit'));

// import { CategoriesIndex, TagsIndex } from './pages/categories/IndexCatTag';
// import { CategoryEdit, TagEdit } from './pages/categories/EditCatTag';

// const { CategoriesIndex, TagsIndex } = lazy(() => import('./pages/categories/IndexCatTag'));
// const { CategoryEdit, TagEdit } = lazy(() => import('./pages/categories/EditCatTag'));

// import StringsIndex from './pages/strings/Index';


// import Support from './pages/Support';
// import Settings from './pages/Settings';

const Support = lazy(() => import('./pages/Support'));
const Settings = lazy(() => import('./pages/Settings'));


import { RSSEdit } from './pages/rss/EditRss';
import { RSSIndex } from './pages/rss/IndexRss';


// const { RSSEdit } = lazy(() => import('./pages/rss/EditRss'));
// const { RSSIndex } = lazy(() => import('./pages/rss/IndexRss'));


const MenusIndex = lazy(() => import('./pages/menus/Index'));
const MenusEdit = lazy(() => import('./pages/menus/Edit'));

const MenuitemsIndex = lazy(() => import('./pages/menuitems/Index'));
const MenuitemsEdit = lazy(() => import('./pages/menuitems/Edit'));

const MainsliderIndex = lazy(() => import('./pages/mainslider/Index'));
const MosaicIndex = lazy(() => import('./pages/mosaic/Index'));

const PagesIndex = lazy(() => import('./pages/pages/Index'));
const PageEdit = lazy(() => import('./pages/pages/Edit'));

const BannerIndex = lazy(() => import('./pages/banners/Index'));
const BannerEdit = lazy(() => import('./pages/banners/Edit'));

import './style.css';

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchAuthStatus = async () => {
      const user = await checkAuthStatus();
      setIsAuthenticated(!!user.login);
      setUser(user);
    };
    fetchAuthStatus();
  }, []);

  const logoutUser = () => {
    setUser({});
    setIsAuthenticated(false);
  };

  const loginUser = async () => {
    const user = await checkAuthStatus();
    setIsAuthenticated(!!user.login);
    setUser(user);
  };
  if (isAuthenticated === null) return <></>;
  return (
    <UserContext.Provider value={{ user, loginUser, logoutUser }}>
      <Suspense fallback={<div style={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Outlet />}>
            <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to="/" replace />} />
            <Route element={<AdminPageLayout />}>
              <Route path="/" element={isAuthenticated ? <Navigate to="/posts" replace /> : <Navigate to="/login" replace />} />

              <Route path="users" element={isAuthenticated ? <UsersIndex /> : <Navigate to="/login" replace />} />
              <Route path="users/add" element={isAuthenticated ? <UserEdit /> : <Navigate to="/login" replace />} />
              <Route path="user/:userId" element={isAuthenticated ? <UserEdit /> : <Navigate to="/login" replace />} />

              <Route path="support" element={isAuthenticated ? <Support /> : <Navigate to="/login" replace />} />
              <Route path="settings" element={isAuthenticated ? <Settings /> : <Navigate to="/login" replace />} />

              <Route path="tags" element={isAuthenticated ? <TagsIndex /> : <Navigate to="/login" replace />} />
              <Route path="tags/add" element={isAuthenticated ? <TagEdit /> : <Navigate to="/login" replace />} />
              <Route path="tags/add/:lang_code" element={isAuthenticated ? <TagEdit /> : <Navigate to="/login" replace />} />
              <Route path="tags/add/:lang_code/:orig_id" element={isAuthenticated ? <TagEdit /> : <Navigate to="/login" replace />} />
              <Route path="tag/:id" element={isAuthenticated ? <TagEdit /> : <Navigate to="/login" replace />} />

              <Route path="news" element={isAuthenticated ? <NewsIndex /> : <Navigate to="/login" replace />} />
              <Route path="news/add" element={isAuthenticated ? <NewsEdit /> : <Navigate to="/login" replace />} />
              <Route path="news/add/:lang_code/:orig_id" element={isAuthenticated ? <NewsEdit /> : <Navigate to="/login" replace />} />
              <Route path="news/add/:lang_code" element={isAuthenticated ? <NewsEdit /> : <Navigate to="/login" replace />} />
              <Route path="news/:id" element={isAuthenticated ? <NewsEdit /> : <Navigate to="/login" replace />} />

              <Route path="posts" element={isAuthenticated ? <PostsIndex /> : <Navigate to="/login" replace />} />
              <Route path="posts/add" element={isAuthenticated ? <PostEdit /> : <Navigate to="/login" replace />} />
              <Route path="posts/add/:lang_code/:orig_id" element={isAuthenticated ? <PostEdit /> : <Navigate to="/login" replace />} />
              <Route path="posts/add/:lang_code" element={isAuthenticated ? <PostEdit /> : <Navigate to="/login" replace />} />
              <Route path="post/:id" element={isAuthenticated ? <PostEdit /> : <Navigate to="/login" replace />} />

              <Route path="post-categories" element={isAuthenticated ? <PostCategoriesIndex /> : <Navigate to="/login" replace />} />
              <Route path="post-categories/add" element={isAuthenticated ? <PostCategoryEdit /> : <Navigate to="/login" replace />} />
              <Route path="post-categories/add/:lang_code/:orig_id" element={isAuthenticated ? <PostCategoryEdit /> : <Navigate to="/login" replace />} />
              <Route path="post-categories/add/:lang_code" element={isAuthenticated ? <PostCategoryEdit /> : <Navigate to="/login" replace />} />
              <Route path="post-category/:id" element={isAuthenticated ? <PostCategoryEdit /> : <Navigate to="/login" replace />} />

              <Route path="news-categories" element={isAuthenticated ? <NewsCategoriesIndex /> : <Navigate to="/login" replace />} />
              <Route path="news-categories/add" element={isAuthenticated ? <NewsCategoryEdit /> : <Navigate to="/login" replace />} />
              <Route path="news-categories/add/:lang_code/:orig_id" element={isAuthenticated ? <NewsCategoryEdit /> : <Navigate to="/login" replace />} />
              <Route path="news-categories/add/:lang_code" element={isAuthenticated ? <NewsCategoryEdit /> : <Navigate to="/login" replace />} />
              <Route path="news-category/:id" element={isAuthenticated ? <NewsCategoryEdit /> : <Navigate to="/login" replace />} />


              <Route path="strings-admin" element={isAuthenticated ? <StringsIndex type="strings-admin" /> : <Navigate to="/login" replace />} />
              <Route path="strings-front" element={isAuthenticated ? <StringsIndex type="strings-front" /> : <Navigate to="/login" replace />} />

              <Route path="pages" element={isAuthenticated ? <PagesIndex /> : <Navigate to="/login" replace />} />
              <Route path="pages/add" element={isAuthenticated ? <PageEdit /> : <Navigate to="/login" replace />} />
              <Route path="pages/add/:lang_code/:orig_id" element={isAuthenticated ? <PageEdit /> : <Navigate to="/login" replace />} />
              <Route path="pages/add/:lang_code" element={isAuthenticated ? <PageEdit /> : <Navigate to="/login" replace />} />
              <Route path="page/:id" element={isAuthenticated ? <PageEdit /> : <Navigate to="/login" replace />} />

              {/* <Route path="rss" element={isAuthenticated ? <RSSIndex /> : <Navigate to="/login" replace />} />
              <Route path="rss/add" element={isAuthenticated ? <RSSEdit /> : <Navigate to="/login" replace />} />
              <Route path="rss/:id" element={isAuthenticated ? <RSSEdit /> : <Navigate to="/login" replace />} /> */}

              <Route path="frontend/banners" element={isAuthenticated ? <BannerIndex /> : <Navigate to="/login" replace />} />
              <Route path="frontend/banner/add" element={isAuthenticated ? <BannerEdit /> : <Navigate to="/login" replace />} />
              <Route path="frontend/banner/add/:lang_code" element={isAuthenticated ? <BannerEdit /> : <Navigate to="/login" replace />} />
              <Route path="frontend/banner/:id" element={isAuthenticated ? <BannerEdit /> : <Navigate to="/login" replace />} />

              <Route path="frontend/menus" element={isAuthenticated ? <MenusIndex /> : <Navigate to="/login" replace />} />
              <Route path="frontend/menu/add" element={isAuthenticated ? <MenusEdit /> : <Navigate to="/login" replace />} />
              <Route path="frontend/menu/add/:lang_code" element={isAuthenticated ? <MenusEdit /> : <Navigate to="/login" replace />} />
              <Route path="frontend/menu/add/:lang_code/:orig_id" element={isAuthenticated ? <MenusEdit /> : <Navigate to="/login" replace />} />
              <Route path="frontend/menu/:id" element={isAuthenticated ? <MenusEdit /> : <Navigate to="/login" replace />} />

              <Route path="frontend/menuitems/:menu_id" element={isAuthenticated ? <MenuitemsIndex /> : <Navigate to="/login" replace />} />
              <Route path="frontend/menuitem/add/:menu_id" element={isAuthenticated ? <MenuitemsEdit /> : <Navigate to="/login" replace />} />
              <Route path="frontend/menuitem/:id" element={isAuthenticated ? <MenuitemsEdit /> : <Navigate to="/login" replace />} />

              <Route path="frontend/mainslider" element={isAuthenticated ? <MainsliderIndex /> : <Navigate to="/login" replace />} />
              <Route path="frontend/lifestyle-mosaic" element={isAuthenticated ? <MosaicIndex /> : <Navigate to="/login" replace />} />

              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </UserContext.Provider>
  );
}