/**
 * Translate description: Title
 */

import React from 'react';

import Box from '@mui/joy/Box';

export default function Title({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        mb: 1,
        gap: 1,
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: { xs: 'start', sm: 'center' },
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: 'background.body'
      }}
    >
      {children}
    </Box>
  )
}