/**
 * Translate description: Modal delete confirmation
 */
import { useContext } from 'react';

import { useLanguageContext } from "../../context/LanguageContext";
import AppContext from '../../context/AppContext';
import InfoContext from '../../context/InfoContext';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DeleteForever from '@mui/icons-material/DeleteForever';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import axios from 'axios';

async function deleteItem(link, id) {
  try {
    const url = `${process.env.REACT_APP_TMBACK_URL}/api/${link}/delete`;
    return await axios.delete(url, { withCredentials: true, data: { id } });
  } catch (error) {
    console.error('Ошибка удаления: ', error.message);
    return error.response?.data?.message;
  }
}


export default function ModalDelete() {
  const { t } = useLanguageContext();
  const { modal, setModal, setListItems } = useContext(AppContext);
  const { setInfo } = useContext(InfoContext);

  return (
    <Modal open={modal.show} onClose={() => setModal({ show: false })}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>
          <WarningRoundedIcon />
          {t('Confirmation')}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {t('Are you sure you want to delete')} id: {modal.id}?
        </DialogContent>
        <DialogActions>
          <Button variant="solid" color="danger" onClick={async () => {
            const res = await deleteItem(modal.link, modal.id);
            if (res?.data?.affectedRows) {
              setListItems((list) => list.filter(item => item.id != modal.id))
              setInfo({ type: 'success', show: true, message: t('Item deleted') });
            } else {
              console.error('Error deleting item', res)
              setInfo({ type: 'danger', show: true, message: `${t('Error deleting item')}` });
            }
            setModal({ show: false });
            setTimeout(() => setInfo({ type: '', show: false, message: '' }), 6000);
          }}>
            {t('Delete')}
          </Button>
          <Button variant="plain" color="neutral" onClick={() => setModal({ show: false })}>
            {t('Cancel')}
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
}