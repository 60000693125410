/**
 * Translate description: Pagination
 */

import * as React from 'react';

import { useLanguageContext } from "../../context/LanguageContext";

import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

export default function Pagination({
  paginator,
  setPaginator
}) {

  const { t } = useLanguageContext();

  return (
    <Box
      className="Pagination-laptopUp"
      sx={{
        pt: 2,
        gap: 1,
        [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
        display: {
          xs: 'none',
          md: 'flex',
        },
        alignItems: 'center',
      }}
    >
      <Button
        size="sm"
        variant="outlined"
        color="neutral"
        startDecorator={<KeyboardDoubleArrowLeftIcon />}
        onClick={() => {
          setPaginator({ ...paginator, currentPage: 1 });
        }}
        disabled={paginator.currentPage === 1}
      >
        {t('First')}
      </Button>
      <Button
        size="sm"
        variant="outlined"
        color="neutral"
        startDecorator={<KeyboardArrowLeftIcon />}
        onClick={() => {
          setPaginator({ ...paginator, currentPage: paginator.currentPage - 1 });
        }}
        disabled={paginator.currentPage === 1}
      >
        {t('Previous')}
      </Button>

      <Box sx={{ flex: 1 }} />
      {/* {['1', '2', '3', '…', '8', '9', '10'].map((page) => (
          <IconButton
            key={page}
            size="sm"
            variant={Number(page) ? 'outlined' : 'plain'}
            color="neutral"
          >
            {page}
          </IconButton>
        ))} */}
      <Typography level="body-sm" mx="auto">
        {t('Page')} {paginator.currentPage} {t('of')} {paginator.totalPages}
      </Typography>
      <Box sx={{ flex: 1 }} />

      <Button
        size="sm"
        variant="outlined"
        color="neutral"
        endDecorator={<KeyboardArrowRightIcon />}
        onClick={() => {
          setPaginator({ ...paginator, currentPage: paginator.currentPage + 1 });
        }}
        disabled={paginator.currentPage >= paginator.totalPages}
      >
        {t('Next')}
      </Button>
      <Button
        size="sm"
        variant="outlined"
        color="neutral"
        endDecorator={<KeyboardDoubleArrowRightIcon />}
        onClick={() => {
          setPaginator({ ...paginator, currentPage: paginator.totalPages });
        }}
        disabled={paginator.currentPage >= paginator.totalPages}
      >
        {t('Last')}
      </Button>
    </Box>
  )
}