/**
 * Translate description: Admin page layout
 */
import { useContext } from 'react';
import InfoContext from '../../context/InfoContext';

import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Snackbar from '@mui/joy/Snackbar';
import Box from '@mui/joy/Box';

import Header from './Header';
import Sidebar from './Sidebar';
import MainContent from './MainContent';
import { Outlet } from 'react-router-dom';

export default function AdminPageLayout({ children }) {
  const { info } = useContext(InfoContext);
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Header />
        <Sidebar />
        <MainContent>
          <Outlet />
        </MainContent>
        <Snackbar open={info.show} color={info.type}>{info.message}</Snackbar>
      </Box>
    </CssVarsProvider>
  );
}