/**
 * Translate description: Edit/Delete dropdown menu
 */

import * as React from 'react';
import { Link as ReactLink } from 'react-router-dom';

import { useLanguageContext } from "../../context/LanguageContext";

import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';

import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';


export default function ItemEditDelete({
  id,
  editLink,
  deleteLink,
  setModal
}) {
  const { t } = useLanguageContext();
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }} placement="bottom-end">
        <ReactLink to={`/${editLink}/${id}`} style={{ textDecoration: 'none' }}><MenuItem>{t('Edit')}</MenuItem></ReactLink>
        <Divider />
        <MenuItem
          color="danger"
          onClick={() => {
            setModal({ show: true, id: id, link: deleteLink });
          }}
        >{t('Delete')}</MenuItem>
      </Menu>
    </Dropdown>
  );
}