/**
 * Translate description: Pagination mobile
 */

import * as React from 'react';

import { useLanguageContext } from "../../context/LanguageContext";

import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

export default function Pagination({
  paginator,
  setPaginator
}) {

  const { t } = useLanguageContext();

  return (
    <Box
      className="Pagination-mobile"
      sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', py: 2 }}
    >
      <IconButton
        aria-label={t('Previous')}
        variant="outlined"
        color="neutral"
        size="sm"
        onClick={() => {
          setPaginator({ ...paginator, currentPage: 1 });
        }}
        disabled={paginator.currentPage === 1}
      >
        <KeyboardDoubleArrowLeftIcon />
      </IconButton>
      <IconButton
        aria-label={t('Previous')}
        variant="outlined"
        color="neutral"
        size="sm"
        onClick={() => {
          setPaginator({ ...paginator, currentPage: paginator.currentPage - 1 });
        }}
        disabled={paginator.currentPage === 1}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <Typography level="body-sm" mx="auto">
        {t('Page')} {paginator.currentPage} {t('of')} {paginator.totalPages}
      </Typography>
      <IconButton
        aria-label={t('Next')}
        variant="outlined"
        color="neutral"
        size="sm"
        onClick={() => {
          setPaginator({ ...paginator, currentPage: paginator.currentPage + 1 });
        }}
        disabled={paginator.currentPage >= paginator.totalPages}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
      <IconButton
        aria-label={t('Next')}
        variant="outlined"
        color="neutral"
        size="sm"
        onClick={() => {
          setPaginator({ ...paginator, currentPage: paginator.totalPages });
        }}
        disabled={paginator.currentPage >= paginator.totalPages}
      >
        <KeyboardDoubleArrowRightIcon />
      </IconButton>
    </Box>
  )
}