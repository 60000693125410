import axios from 'axios';

export const checkAuthStatus = async () => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_TMBACK_URL}/api/identity/auth/me`, {}, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.error('Ошибка проверки статуса аутентификации: ', error.message);
    return false;
  }
};

export async function checkLogin(username, password) {
  try {
    const res = await axios.post(`${process.env.REACT_APP_TMBACK_URL}/api/identity/auth/signin`, { username, password }, { withCredentials: true });
    return res.request?.status;
  } catch (err) {
    console.log(`error status: ${err.request?.status}`);
    return err.request?.status;
  }
};

export async function checkLogout() {
  try {
    const res = await axios.post(`${process.env.REACT_APP_TMBACK_URL}/api/identity/auth/logout`, {}, { withCredentials: true });
    return res.request?.status;
  } catch (err) {
    console.log(`error status: ${err.request?.status}`);
    return err.request?.status;
  }
};
