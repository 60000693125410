import * as React from 'react';
import Input from '@mui/joy/Input';

function DebounceInput(props) {
    const { handleDebounce, debounceTimeout, ...rest } = props;

    const timerRef = React.useRef();

    const handleChange = (event) => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            handleDebounce(event);
        }, debounceTimeout);
    };

    return <Input {...rest} onChange={handleChange} />;
}

export default DebounceInput