/**
 * Translate description: Form control field
 */

import { Box } from '@mui/joy';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import { useContext } from "react";

export function makeField(context) {
    return function Field({ name, label, placeholder, value, setter, Element, elementSettings, rightTitleDecorator, required }) {
        const { errors, setErrors } = useContext(context)
        Element = Element || Input;
        const hasError = (fieldName) => fieldName in errors;

        return <FormControl error={hasError(name)}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <FormLabel>{label}{required ? '*' : ''}</FormLabel>
                {rightTitleDecorator}
            </Box>
            <Element placeholder={placeholder || label} value={value} onChange={(e, v) => { setter(v ?? e.target.value); setErrors({}) }} {...elementSettings} />
            <FormHelperText>{errors[name]?.message}</FormHelperText>
        </FormControl>
    }
}
