import { Input } from "@mui/joy";
import moment from "moment";
import { useMemo } from "react";

// Сервер всегда возвращает время в utc и ожидает что клиент будет отправлять время в utc
// value это строка типа "2024-05-13 15:32:46" , по дефолту ожидается в utc, установить utc=false если передается строка в локальном времени
// Если у юзера есть возможность выбирать дату, то работать надо всегда в локальном времени (utc=false)
// соотвественно перед получением с сервера ее нужно сначала привести к локальному (timestring_from_server ? moment.utc(timestring_from_server).local().format('YYYY-MM-DD HH:mm:ss'):null;)
// а перед отправкой на сервер обратно вернуть в utc ( local_string ? moment(local_string).utc().format('YYYY-MM-DD HH:mm:ss') : null)
// Если дату нужно только отобразить, то можно просто подсунуть value полученый с сервера (utc=true)
export function DateInput({ value, utc = true, ...rest }) {
    let local_time = useMemo(() => utc ? moment.utc(value).local().format('YYYY-MM-DD HH:mm:ss') : value, [value])
    return <Input value={local_time} type='datetime-local' {...rest} ></Input>
}