/**
 * Translate description: Breadcrumbs
 */

import { React } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { useLanguageContext } from "../../context/LanguageContext";

import Box from '@mui/joy/Box';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Typography from '@mui/joy/Typography';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';


export default function Bread({ path }) {
  const { t } = useLanguageContext();

  const curr = path.pop();
  const linkStyle = { textDecoration: 'none', display: 'block', width: '100%' };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Breadcrumbs
        size="sm"
        aria-label="breadcrumbs"
        separator={<ChevronRightRoundedIcon fontSize="sm" />}
        sx={{ pl: 0 }}
      >

        <ReactLink to="/" color="neutral" aria-label={t('Home')} style={linkStyle}>
          <Typography
            color="neutral"
            level="body-sm"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <HomeRoundedIcon />
          </Typography>
        </ReactLink>

        {path.map((item, index) => (
          <ReactLink key={index} to={item.path} color="neutral" aria-label={item.name} style={linkStyle}>
            <Typography
              color="neutral"
              fontWeight={500}
              fontSize={14}
            >
              {item.name}
            </Typography>
          </ReactLink>
        ))}

        <Typography color="primary" fontWeight={500} fontSize={14}>
          {curr.name}
        </Typography>
      </Breadcrumbs>
    </Box>
  )
}