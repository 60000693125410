module.exports.paramMessages = {
    originalNotFound: {
        variants: ['category', 'tag'],
        source: (type) => {
            return `Edit page for ${type}`
        },
        message: (type, variants, message_type) => {
            if (!variants.includes(type)) throw new Error(`Parametriezed message ${message_type} does not allow type ${type}`)
            return `Original ${type} not found`
        }
    }
}
