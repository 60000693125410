/**
 * Translate description: Slug input component
 */
import LoopIcon from '@mui/icons-material/Loop';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button } from "@mui/joy";
import Input from "@mui/joy/Input";
import { useLanguageContext } from "../context/LanguageContext";
import { toSlug } from "../utils";

export function SlugInput(props = {}) {
    const { t } = useLanguageContext();
    const { setSlug, source_value, ...rest } = props;
    return <Box>
        <Input {...rest}
            endDecorator={
                source_value &&
                <Button
                    size="sm"
                    onClick={() => {
                        setSlug(toSlug(source_value))
                    }}

                >
                    {rest.value ? <LoopIcon /> : <EditIcon />}
                </Button>
            }
        ></Input>

    </Box>
}