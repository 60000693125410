/**
 * Translate description: Index page for RSS
 */

import { useEffect, useContext } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { useLanguageContext } from "../../context/LanguageContext";
import AppContext from '../../context/AppContext';
import AppProvider from '../../provider/AppProvider';

import Bread from '../../components/layout/BreadCrumbs';
import Title from '../../components/layout/Title';

import Typography from '@mui/joy/Typography';

import Button from '@mui/joy/Button';

import AddCircleOutlineRounded from '@mui/icons-material/AddCircleOutlineRounded';

import ModalDelete from '../../components/layout/ModalDelete';

import { RSSTable } from './components/Table';


import { RssApi } from '../../utils/crud/rss_api';
import { RSSList } from './components/List';
const rss_api = new RssApi();

function _Index() {
  const { t } = useLanguageContext();

  const { setListItems } = useContext(AppContext);
  const { paginator } = useContext(AppContext);
  const { sorting } = useContext(AppContext);
  const { filter } = useContext(AppContext);
  const { setLoading } = useContext(AppContext);

  const { currentLang } = useContext(AppContext);

  useEffect(() => {
    const getRSS = async () => {
      setLoading(true);
      const res = await rss_api.get({
        paginator,
        sorting,
        filter: filter,
      });
      setListItems(res.data);
      paginator.totalPages = res.pages;
      setLoading(false);
    };
    getRSS();
  }, [paginator.currentPage, filter, sorting]);


  return (
    <>
      <Bread path={[{ name: t("RSS") }]} />
      <Title>
        <Typography level="h2" component="h1">
          {t("RSS")}
        </Typography>
        <ReactLink to={`/rss/add/`} textDecoration="none">
          <Button
            color="primary"
            startDecorator={<AddCircleOutlineRounded />}
            size="sm"
          >
            {t("New RSS")}
          </Button>
        </ReactLink>
      </Title>
      <RSSTable />
      <RSSList />
      <ModalDelete />
    </ >
  );
}

export function RSSIndex() {
  return (
    <AppProvider>
      <_Index />
    </AppProvider>
  );
}