/**
 * Translate description: Form component for rss
 */
import React, { useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';

import { langByCode, langById, useLanguageContext } from "../../../context/LanguageContext";
import { EditRssContext } from '../EditRssContext';

import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import FormHelperText from '@mui/joy/FormHelperText';
import Textarea from '@mui/joy/Textarea';
import Sheet from '@mui/joy/Sheet';
import Avatar from '@mui/joy/Avatar';

import { capitalizeFirstLetter } from '../../../utils';

import { SelectSourceLang2 } from '../../../components/SelectSourceLang';
import { SlugInput } from '../../../components/SlugInput';
import { makeField } from '../../../components/makeField';
import moment from 'moment';
import { DateInput } from '../../../components/DateInput';
import { InputSearchAjax } from '../../../components/InputSearchAjax';

const Field = makeField(EditRssContext)
export function RSSForm() {
    const { t } = useLanguageContext();

    const { errors,
        name, setName,
        url, setUrl,
        author, setAuthor,
        categories, setCategories,
        language,
        interval_minutes, setIntervalMinutes

    } = useContext(EditRssContext)
    let name_label = t('Name')
    let url_label = t('URL')
    let interval_label = t('Interval')
    let author_label = t('Author')

    return (
        <>
            <Field name="name" label={name_label} value={name} setter={setName} />
            <Field name="url" label={url_label} value={url} setter={setUrl} />
            <Field name="interval" label={interval_label} value={interval_minutes} setter={setIntervalMinutes} elementSettings={{ type: 'number' }} />
            <Field name="categories" label={t('Categories')} value={categories} setter={setCategories} Element={InputSearchAjax} elementSettings={{ multiple: true, url: '/categories/get', lang_id: language?.id, debouncetimeout: 500, defaultValue: [] }} />
            <Field name="author" label={author_label} setter={setAuthor} value={author} Element={InputSearchAjax} elementSettings={{ url: '/users/get', method: 'get', debouncetimeout: 500, defaultValue: [] }} />

        </>
    )
}
export function RSSSidebar() {
    let { id } = useParams();
    const { t } = useLanguageContext();
    const { language, setLanguage, setErrors, errors, allowed_langs,
        last_check
    } = useContext(EditRssContext)
    const setLanguageCode = (code) => {
        setErrors({})
        setLanguage(langByCode(code))
    }
    const hasError = (fieldName) => fieldName in errors;

    let language_label = t('Language')
    return <Sheet style={{
        padding: 10,
        borderRadius: 5,
        minWidth: '250px',
        position: 'sticky',
        top: '56px',
        height: 'max-content'

    }}
        variant="outlined"
    >
        <FormControl sx={{ mb: 1 }} error={hasError('language')}>
            <FormLabel >{language_label}: </FormLabel>
            <SelectSourceLang2
                languages={allowed_langs}
                currentLang={language?.code || 'en'}
                setCurrentLang={setLanguageCode}
            />
            <FormHelperText>{errors['language']?.message}</FormHelperText>
        </FormControl>
        {last_check &&
            <Field name="last_check" Element={DateInput/*перед копипастой ознакомиться с комментом в DateInput.js*/} label={t('Last check') + ":"}
                value={last_check} elementSettings={{ type: 'datetime-local', disabled: true }} />
        }

    </Sheet>
}
